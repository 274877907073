<template>
  <form id="version-switcher" @submit.prevent="submitConstraintChange">
    <label class="sr-only" for="cms-constraint"> Craft CMS Version </label>

    <select
      id="cms-constraint"
      v-model="cmsConstraint"
      class="bg-transparent rounded-md border-transparent text-lg font-bold pl-2 py-1 text-inherit"
      @change="onCmsConstraintChange"
    >
      <option
        v-for="(option, optionKey) in cmsConstraintOptions"
        :key="optionKey"
        :value="option.value"
        class="text-black"
      >
        {{ option.label }}
      </option>
    </select>
  </form>
</template>

<script setup lang="ts">
  import {computed, navigateTo, useAppStore, useRoute} from '#imports';

  const appStore = useAppStore();
  const route = useRoute();

  // Computed
  const getCmsConstraintSelectOptions = computed(
    () => appStore.getCmsConstraintSelectOptions,
  );
  const getCmsConstraintQuery = computed(() => appStore.getCmsConstraintQuery);
  const cmsConstraint = computed({
    get: () => appStore.cmsConstraint,
    set: (newValue) => appStore.setCmsConstraint(newValue),
  });
  const cmsConstraintOptions = computed(
    () => getCmsConstraintSelectOptions.value,
  );

  // Methods
  const onCmsConstraintChange = () => {
    submitConstraintChange();
  };
  const submitConstraintChange = () => {
    const path = route.path;
    const hash = route.hash;
    const query = getCmsConstraintQuery.value(route.query);

    navigateTo({
      path,
      hash,
      query,
    });
  };
</script>
